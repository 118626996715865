export default [
    {
        to: {name: 'admin.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'admin.dentists.index'},
        icon: 'mdi-podcast',
        text: 'Dentists',
    },
    // {
    //     to: {name: 'admin.price-plans.index'},
    //     icon: 'mdi-application-cog',
    //     text: 'Price Plans',
    // },
    // {
    //     to: {name: 'admin.users.index'},
    //     icon: 'mdi-account',
    //     text: 'Users',
    // },
]
